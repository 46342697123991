import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { workCenterGuard } from '@pt/shared/guards';
import { navUrls } from '@pt/shared/utils';
import { ShellComponent } from '@pt/shell/containers/shell/shell.component';
import { PileTurnerComponent } from '@pt/modules/pile-turner/pile-turner.component';
import { authorizeGuard } from 'chronos-shared';

const routes: Routes = [
  {
    path: navUrls.root,
    component: ShellComponent,
    canActivate: [authorizeGuard],
    canActivateChild: [workCenterGuard],
    children: [
      {
        path: navUrls.pileTurner,
        component: PileTurnerComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ShellRoutingModule {}
