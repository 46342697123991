<div *ngIf="selectedWorkCenter" class="work-center-details">
  <div class="details">
    <div class="details__header">
      <div class="details__title">
        {{ selectedWorkCenter.workCenter.externalWorkCenterId }} {{ selectedWorkCenter.workCenter.workCenterName }}
      </div>
      <p-selectButton
        styleClass="chronos-select-button-dark"
        [options]="viewOptions"
        [(ngModel)]="selectedView"
        (onChange)="updateMountedPalletData()"
        unselectable="false"
      >
        <ng-template let-item pTemplate>
          <span>{{ item.label | translate }}</span>
        </ng-template>
      </p-selectButton>
    </div>
    <div *ngIf="selectedView === VIEW.OrderView; else mountedPalletView">
      <pt-production-order-list>
        <ng-container *ngIf="selectedWorkCenter.nextOrder">
          <pt-production-order [productionOrder]="selectedWorkCenter.nextOrder"></pt-production-order>
        </ng-container>
        <ng-container *ngIf="selectedWorkCenter.activeOrder">
          <pt-production-order
            [productionOrder]="selectedWorkCenter.activeOrder"
            [workCenterId]="selectedWorkCenter.workCenterId"
            (dummyPalletCreated)="updateMountedPalletData()"
          ></pt-production-order>
        </ng-container>
      </pt-production-order-list>
      <pt-selected-pallet
        [selectedPallet]="selectedPallet"
        [workCenterSelected]="selectedWorkCenter"
        (mountButtonClicked)="mountPallet($event)"
        (cancelButtonClicked)="unselectPallet()"
      ></pt-selected-pallet>
      <div class="footer">
        <div class="footer__row">
          <pt-tile
            [label]="'PILE_TURNER.MOUNTED' | translate | uppercase"
            [value]="selectedWorkCenter.mounted.quantity | formatQuantity : false"
            [unit]="selectedWorkCenter.mounted.quantity.unitId"
          ></pt-tile>
          <ng-container *ngIf="selectedWorkCenter.toBeDismounted.containerCount as dismountableCount">
            <pt-dismount-button
              *ngIf="dismountableCount"
              [dismountableCount]="dismountableCount"
              (click)="onDismountClick()"
            ></pt-dismount-button>
          </ng-container>
          <lib-pallet-indicator
            class="pallet-indicator"
            [title]="'PARAMETER_FIELD.' + this.articleClassification + '_LIST' | translate"
            [palletCount]="selectedWorkCenter.mounted.containerCount || 0"
            [large]="true"
            (clicked)="openPalletList()"
          ></lib-pallet-indicator>
        </div>
      </div>
    </div>
    <ng-template #mountedPalletView>
      <pt-mounted-pallet-table [tableData]="mountedPalletData" [buttonTemplate]="buttonTemplate"></pt-mounted-pallet-table>
    </ng-template>
  </div>
</div>

<ng-template #buttonTemplate let-rowData let-rowNode="rowNode">
  <ng-container *ngIf="rowData.itemType === ITEM_TYPE.MaterialMounted">
    <i (click)="onPalletDismountClick(rowData, rowNode)" class="work-center-details__dismount-button icon-unmount-pallet"></i>
  </ng-container>
</ng-template>
