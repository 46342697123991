import { Injectable } from '@angular/core';
import { ActiveOrderDsService, WorkCenterDsService } from '@pt/core/data-services';
import { filter, map, switchMap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ActiveProductionOrder, PileTurnerWorkCenter, RunPhase, RunSubPhaseType } from 'chronos-core-client';

@Injectable({
  providedIn: 'root'
})
export class ActiveOrderPanelService {
  constructor(private workCenterDsService: WorkCenterDsService, private activeOrderDsService: ActiveOrderDsService) {}

  public getActiveOrder(): Observable<ActiveProductionOrder> {
    return this.workCenterDsService.getActiveWorkCenter().pipe(
      filter((workCenter) => !!workCenter),
      map((workCenter) => workCenter as PileTurnerWorkCenter),
      switchMap((workCenter) => this.activeOrderDsService.getActiveOrder(workCenter.workCenterId))
    );
  }

  public getRunPhases(productionOrderId: number): Observable<RunPhase[]> {
    return this.activeOrderDsService.getProductionOrderRunPhases(productionOrderId);
  }

  public isQaHistorySubPhase(phase: RunPhase): boolean {
    return phase.runSubPhaseType === RunSubPhaseType.SetupQaHistory;
  }

  public isLineClearanceSubPhase(phase: RunPhase): boolean {
    return phase.runSubPhaseType === RunSubPhaseType.SetupLineClearance;
  }

  public isQaChecksSubPhase(phase: RunPhase): boolean {
    return phase.runSubPhaseType === RunSubPhaseType.SetupInProgressFinishingCheck;
  }
}
