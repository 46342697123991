import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ArticleDescription, PrimaryMaterialMounted, ProductionOrderInfo, MountedMaterialStatus } from 'chronos-core-client';

@Component({
  selector: 'pt-pallet-list',
  templateUrl: './pallet-list.component.html',
  styleUrls: ['./pallet-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PalletListComponent {
  @Input() public palletList: PrimaryMaterialMounted[] = [];
  @Input() public article?: ArticleDescription;
  @Input() public isActiveOrder = false;
  @Input() public productionOrderInfo?: ProductionOrderInfo;

  @Output() public whiteWasteAdded = new EventEmitter<[number, number]>();
  @Output() public whiteWasteReset = new EventEmitter<number>();
  @Output() public replaceDummyClicked = new EventEmitter<[number, number]>();
  @Output() public dismountClicked = new EventEmitter<PrimaryMaterialMounted>();

  public shouldAllowContainerActions(container: PrimaryMaterialMounted): boolean {
    return this.isActiveOrder && container.mountedMaterialStatus !== MountedMaterialStatus.Consumed;
  }

  public shouldDismountBeDisabled(container: PrimaryMaterialMounted): boolean {
    return container.mountedMaterialStatus === MountedMaterialStatus.Active || !this.isActiveOrder;
  }

  public onWhiteWasteAdd([mountedMaterialId, whiteWaste]: [number, number]) {
    this.whiteWasteAdded.emit([mountedMaterialId, whiteWaste]);
  }

  public resetPalletWhiteWaste(mountedMaterialId: number) {
    this.whiteWasteReset.emit(mountedMaterialId);
  }

  public onReplaceClick(mountedMaterialId: number, dummyContainerId: number): void {
    this.replaceDummyClicked.emit([mountedMaterialId, dummyContainerId]);
  }

  public onDismountClick(pallet: PrimaryMaterialMounted): void {
    this.dismountClicked.emit(pallet);
  }
}
