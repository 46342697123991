import { Injectable } from '@angular/core';
import {
  DismountPrimaryContainerEntry,
  MaterialAvailableAtLocation,
  MountPrimaryContainerEntry,
  MountPrimaryContainerResponse,
  PileTurner,
  PileTurnerPalletList,
  PileTurnerScanContainerResponse,
  PileTurnerScanEntry,
  PileTurnerService,
  PrimaryMaterialBlock,
  PrimaryMaterialMounted,
  PrimaryMaterialService,
  ReplaceVirtualPrimaryContainerEntity,
  CreateAndMountVirtualPrimaryContainerEntry,
  VirtualContainerReason,
  VirtualContainerService,
  WebSocketClientService,
  WhiteWasteEntry,
  WorkCenterIdEntry,
  CreatePrimaryVirtualContainerData
} from 'chronos-core-client';
import { concat, Observable, of } from 'rxjs';
import { hubUrls } from '@pt/shared/utils';
import { switchMap } from 'rxjs/operators';
import { PileTurnerWorkCenterService } from '@pt/core/services';
import { Quantity } from 'projects/chronos-core-client/src/public-api';

@Injectable({
  providedIn: 'root'
})
export class PileTurnerDsService {
  constructor(
    private pileTurnerService: PileTurnerService,
    private primaryMaterialService: PrimaryMaterialService,
    private virtualContainerService: VirtualContainerService,
    private webSocketClientService: WebSocketClientService,
    private pileTurnerWorkCenterService: PileTurnerWorkCenterService
  ) {}

  public getPileTurnerInfo(workCenterId: number): Observable<PileTurner> {
    return this.pileTurnerService
      .getPileTurner({ pileTurnerWorkCenterId: workCenterId })
      .pipe(switchMap((pileTurner) => concat(of(pileTurner), this.getPileTurnerInfoNotifications(workCenterId))));
  }

  private getPileTurnerInfoNotifications(pileTurnerId: number): Observable<PileTurner> {
    return this.webSocketClientService.getNotificationsForTopic<PileTurner>(hubUrls.getPileTurnerInfo(pileTurnerId));
  }

  public getMaterialAvailableAtPileTurner(): Observable<MaterialAvailableAtLocation> {
    const workCenterId = this.pileTurnerWorkCenterService.getPileTurnerId();
    return this.pileTurnerService.getMaterialAvailableAtPileTurner({ workCenterId });
  }

  public scanPallet(identificationCode: string, containerId?: number): Observable<PileTurnerScanContainerResponse> {
    const pileTurnerId = this.pileTurnerWorkCenterService.getPileTurnerId();
    return this.pileTurnerService.pileTurnerScanContainer({
      pileTurnerWorkCenterId: pileTurnerId,
      body: {
        identificationCode,
        containerId
      }
    });
  }

  public getPalletList(workCenterId: number): Observable<PileTurnerPalletList> {
    return this.pileTurnerService.getPalletList({ workCenterId });
  }

  public mountPallet(workCenterId: number, primaryContainerEntry: MountPrimaryContainerEntry): Observable<MountPrimaryContainerResponse> {
    return this.primaryMaterialService.mountPrimaryContainer({
      workCenterId,
      body: primaryContainerEntry
    });
  }

  public addContainerWhiteWaste(mountedMaterialId: number, workCenterId: number, whiteWaste: Quantity): Observable<void> {
    return this.primaryMaterialService.addWhiteWasteToMountedMaterial({
      mountedMaterialId,
      body: {
        waste: whiteWaste,
        workCenterId
      }
    });
  }

  public removeContainerWhiteWaste(mountedMaterialId: number, workCenterId: number): Observable<void> {
    return this.primaryMaterialService.deleteWhiteWasteAssignment({
      mountedMaterialId,
      body: {
        workCenterId
      }
    });
  }

  public getMaterialAvailableAtMachine(workCenterId: number): Observable<MaterialAvailableAtLocation> {
    return this.primaryMaterialService.getMaterialAvailableAtMachine({ workCenterId });
  }

  public replaceContainer(
    mountedMaterialId: number,
    workCenterId: number,
    virtualContainerId: number,
    newContainerId: number
  ): Observable<void> {
    return this.primaryMaterialService.replaceVirtualPrimaryContainer({
      mountedMaterialId,
      body: {
        virtualContainerId,
        workCenterId,
        newContainerId
      }
    });
  }

  public dismountContainer(
    containerId: number,
    workCenterId: number,
    mountedMaterialId: number,
    remainingQuantity: number,
    isLabelPrinted: boolean
  ): Observable<void> {
    return this.primaryMaterialService.dismountContainer({
      containerId,
      body: {
        remainingQuantity,
        shouldPrintLabel: isLabelPrinted,
        mountedMaterialId,
        workCenterId
      }
    });
  }

  public nextContainerForDismount(workCenterId: number): Observable<PrimaryMaterialMounted> {
    return this.primaryMaterialService.getNextPrimaryContainerForDismount({ workCenterId });
  }

  public getVirtualContainerReasons(): Observable<VirtualContainerReason[]> {
    return this.virtualContainerService.getVirtualContainerReasons();
  }

  public addVirtualContainer(workCenterId: number, virtualContainer: CreateAndMountVirtualPrimaryContainerEntry): Observable<void> {
    return this.primaryMaterialService.createAndMountVirtualPrimaryContainer({
      workCenterId,
      body: virtualContainer
    });
  }

  public getMountedMaterials(workCenterId: number): Observable<PrimaryMaterialBlock[]> {
    return this.primaryMaterialService.getMountedMaterials({ workCenterId });
  }

  public getPrimaryVirtualContainerCreationData(productionOrderId: number): Observable<CreatePrimaryVirtualContainerData> {
    return this.primaryMaterialService.getPrimaryVirtualContainerCreationData({ productionOrderId });
  }
}
