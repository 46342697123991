import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActiveProductionOrder, ManualMachineMode, RunPhase, RunPhaseType, RunSubPhaseType } from 'chronos-core-client';
import { ActiveOrderPanelService } from './services/active-order-panel/active-order-panel.service';
import { filter, switchMap, tap } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { OrderPhaseItems, SubPhaseItem } from '@app/shared/models';
import { PileTurnerService } from '@pt/modules/pile-turner/services/pile-turner/pile-turner.service';

@Component({
  selector: 'pt-active-order-panel',
  templateUrl: './active-order-panel.component.html',
  styleUrls: ['./active-order-panel.component.scss']
})
export class ActiveOrderPanelComponent implements OnInit, OnDestroy {
  public navigationReadOnly = true;
  public isQaHistoryEnabled = false;
  public isQaChecksEnabled = false;
  public isLineClearanceEnabled = false;

  public activeOrder?: ActiveProductionOrder;

  public readonly orderPhase = RunPhaseType;
  public readonly orderSubPhase = RunSubPhaseType;
  public orderPhases: OrderPhaseItems;
  private subscriptions = new Subscription();

  constructor(
    private activePanelService: ActiveOrderPanelService,
    private translateService: TranslateService,
    private pileTurnerService: PileTurnerService
  ) {}

  public ngOnInit(): void {
    this.subscriptions.add(
      this.activePanelService
        .getActiveOrder()
        .pipe(
          tap((activeOrder) => (this.activeOrder = activeOrder)),
          filter((activeOrder) => !!activeOrder),
          switchMap((activeOrder) => this.activePanelService.getRunPhases(activeOrder.productionOrderId))
        )
        .subscribe((phases) => {
          this.initSubPhases(phases);
        })
    );
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public isActive(phase: string | undefined, phaseType: RunPhaseType | RunSubPhaseType) {
    return phase === phaseType;
  }

  private initSubPhases(phases: RunPhase[]): void {
    this.isQaHistoryEnabled = phases.some((phase) => this.activePanelService.isQaHistorySubPhase(phase));
    this.isLineClearanceEnabled = phases.some((phase) => this.activePanelService.isLineClearanceSubPhase(phase));
    this.isQaChecksEnabled = phases.some((phase) => this.activePanelService.isQaChecksSubPhase(phase));

    this.getOrderPhases();
  }

  private getOrderPhases() {
    this.orderPhases = [
      {
        title: this.translateService.instant('ACTIVE_ORDER.SETUP'),
        icon: 'icon-active-order-setup',
        phaseType: this.orderPhase.Setup,
        hasSubItems: true,
        subItems: this.cretesetupPhaseSubItems()
      },
      {
        title: this.translateService.instant('ACTIVE_ORDER.RUN'),
        icon: 'icon-active-order-run',
        phaseType: this.orderPhase.Run,
        hasSubItems: false,
        subItems: []
      },
      {
        title: this.translateService.instant('ACTIVE_ORDER.FINISH'),
        icon: 'icon-active-order-finish',
        phaseType: this.orderPhase.Finishing,
        hasSubItems: true,
        subItems: this.createFinishingSubItems()
      }
    ];
  }

  private createFinishingSubItems(): SubPhaseItem[] {
    return [
      {
        title: this.translateService.instant('ACTIVE_ORDER.FINISH'),
        subPhaseType: this.orderSubPhase.FinishingOverview,
        isActive: true
      },
      {
        title: this.translateService.instant('ACTIVE_ORDER.END'),
        subPhaseType: this.orderSubPhase.FinishingEnd,
        isActive: true
      }
    ];
  }

  private cretesetupPhaseSubItems(): SubPhaseItem[] {
    return [
      {
        title: this.translateService.instant('ACTIVE_ORDER.LINE_CLEARANCE'),
        subPhaseType: this.orderSubPhase.SetupLineClearance,
        isActive: this.isLineClearanceEnabled
      },
      {
        title: this.translateService.instant('ACTIVE_ORDER.QA_HISTORY'),
        subPhaseType: this.orderSubPhase.SetupQaHistory,
        isActive: this.isQaHistoryEnabled
      },
      {
        title: this.translateService.instant('ACTIVE_ORDER.PARAMETERS'),
        subPhaseType: this.orderSubPhase.SetupParameter,
        isActive: true
      },
      {
        title: this.translateService.instant('ACTIVE_ORDER.QA_SETUP'),
        subPhaseType: this.orderSubPhase.SetupInProgressFinishingCheck,
        isActive: this.isQaChecksEnabled
      },
      {
        title: this.translateService.instant('ACTIVE_ORDER.MATERIAL_WASTE'),
        subPhaseType: this.orderSubPhase.SetupMaterialWaste,
        isActive: true
      }
    ];
  }
}
