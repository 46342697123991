import { Component, OnInit } from '@angular/core';
import { WorkCenterDetailsService } from '@pt/modules/pile-turner/services/work-center-details/work-center-details.service';
import { ArticleDescription, WorkCenterDescription } from 'chronos-core-client';
import { DismountingPalletInformation, LoadingNotificationService, LogService } from 'chronos-shared';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { PileTurnerService } from '@pt/modules/pile-turner/services/pile-turner/pile-turner.service';
import { finalize } from 'rxjs/operators';
import { notificationTopic } from '@pt/shared/utils';

@Component({
  selector: 'pt-dismount-container-modal',
  templateUrl: './dismount-container-modal.component.html',
  styleUrls: ['./dismount-container-modal.component.scss']
})
export class DismountContainerModalComponent implements OnInit {
  public workCenter?: WorkCenterDescription;
  public dismountingPalletInformation?: DismountingPalletInformation;
  public article?: ArticleDescription;
  public printLabel = false;
  public returnSheets = 0;
  public isFormValid = false;
  public allowQuantityChange = false;

  public readonly LOADING_TOPIC = notificationTopic.modalDismountContainer;

  private containerId?: number;

  constructor(
    private config: DynamicDialogConfig,
    private ref: DynamicDialogRef,
    private workCenterDetailsService: WorkCenterDetailsService,
    private pileTurnerService: PileTurnerService
  ) {}

  public ngOnInit(): void {
    this.workCenter = this.config.data?.workCenter;
    this.article = this.config.data?.article;
    this.containerId = this.config.data?.containerId;
    this.dismountingPalletInformation = this.config.data?.dismountingPalletInformation;
    this.allowQuantityChange = this.config.data?.allowQuantityChangeInDismount;
  }

  public dismountContainer(): void {
    if (this.containerId && this.workCenter && this.dismountingPalletInformation) {
      LoadingNotificationService.publish(this.LOADING_TOPIC, true);
      this.workCenterDetailsService
        .dismountContainer(
          this.containerId,
          this.workCenter.workCenterId,
          this.dismountingPalletInformation.mountedMaterialId,
          this.returnSheets,
          this.printLabel
        )
        .pipe(
          finalize(() => {
            LoadingNotificationService.publish(this.LOADING_TOPIC, false);
          })
        )
        .subscribe(() => {
          LogService.success('SUCCESS_MESSAGE.MATERIAL_DISMOUNTED');
          this.ref.close(true);
        });
    } else {
      throw new Error(
        `DismountContainerModalComponent.dismountContainer: containerId = ${typeof this.containerId}, workCenter = ${typeof this
          .workCenter}, dismountingPalletInformation = ${typeof this.dismountingPalletInformation}`
      );
    }
  }

  public onCancel(): void {
    this.ref.close(false);
  }

  public onPrintLabelChange(printLabel: boolean): void {
    this.printLabel = printLabel;
  }

  public onReturnSheetsChange(returnSheets: number): void {
    this.returnSheets = returnSheets;
  }

  public onValidityChange(isValid: boolean): void {
    this.isFormValid = isValid;
  }
}
