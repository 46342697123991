import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { WebSocketClientService } from 'chronos-core-client';
import { Subscription } from 'rxjs';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ModalConfirmComponent, SplashScreenService } from 'chronos-shared';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'pt-shell',
  templateUrl: './shell.component.html',
  styleUrls: ['./shell.component.scss']
})
export class ShellComponent implements OnInit, OnDestroy {
  private subscriptions = new Subscription();
  private connectionModal: DynamicDialogRef;

  constructor(
    private dialogService: DialogService,
    private translateService: TranslateService,
    private webSocketClientService: WebSocketClientService,
    private splashScreenService: SplashScreenService
  ) {}

  public ngOnInit(): void {
    this.subscriptions.add(
      this.webSocketClientService
        .isDisconnected()
        .pipe(filter((disconnected) => disconnected && !this.connectionModal))
        .subscribe(() => {
          this.openModalForConnectionLost();
        })
    );
    this.splashScreenService.hideSplashScreen();
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private openModalForConnectionLost(): void {
    this.connectionModal = this.dialogService.open(ModalConfirmComponent, {
      header: this.translateService.instant('WEB_SOCKET.CONNECTION_LOST'),
      data: {
        question: this.translateService.instant('WEB_SOCKET.MESSAGE'),
        acceptable: true
      }
    });
    this.connectionModal.onClose.pipe(filter((accepted) => !!accepted)).subscribe(() => {
      location.reload();
    });
    this.connectionModal.onDestroy.subscribe(() => (this.connectionModal = null));
  }
}
