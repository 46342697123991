<div class="article-info page-padding chronos-dialog--size-xl">
  <section class="article-data-table">
    <p-table [columns]="tableColumns" [value]="tableData">
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th *ngFor="let col of columns">
            {{ col.header | translate }}
          </th>
        </tr>
      </ng-template>

      <ng-template pTemplate="emptymessage" let-columns>
        <tr>
          <td [attr.colspan]="columns.length">{{ 'MOUNTING.MACHINE_NO_PALLETS_AVAILABLE' | translate }}</td>
        </tr>
      </ng-template>

      <ng-template pTemplate="body" let-rowData let-columns="columns">
        <tr>
          <ng-container *ngFor="let col of columns">
            <td>{{ rowData[col.field] }}</td>
          </ng-container>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="9">{{ 'APP.NO_DATA_FOUND' | translate }}</td>
        </tr>
      </ng-template>
    </p-table>
  </section>
</div>

<lib-modal-footer [showDeclineButton]="true" (declineClicked)="onCloseModal()" [whenLoadingTopic]="LOADING_TOPIC"></lib-modal-footer>
