/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

export enum ManualMachineMode {
  None = 'None',
  ManualWorkingPlace = 'ManualWorkingPlace',
  Cutting = 'Cutting',
  Packing = 'Packing'
}
