/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

export enum LabelPrintResult {
  None = 'None',
  Open = 'Open',
  Done = 'Done',
  Failed = 'Failed'
}
