import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ActiveProductionOrder, MachineScheduleService, ProductionOrderService, RunPhase } from 'chronos-core-client';

@Injectable({
  providedIn: 'root'
})
export class ActiveOrderDsService {
  constructor(private machineScheduleService: MachineScheduleService, private productionOrderService: ProductionOrderService) {}

  public getActiveOrder(workCenterId: number): Observable<ActiveProductionOrder> {
    return this.machineScheduleService.getActiveOrder({ workCenterId });
  }

  public getProductionOrderRunPhases(productionOrderId: number): Observable<RunPhase[]> {
    return this.productionOrderService.getRunPhases({ productionOrderId });
  }
}
