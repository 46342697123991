/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

export enum RunPhaseType {
  None = 'None',
  Setup = 'Setup',
  Run = 'Run',
  Finishing = 'Finishing'
}
