<section class="material-table">
  <p-table
    #dt
    [globalFilterFields]="['identificationCode', 'internalBatchId', 'externalBatchId']"
    [columns]="tableColumns"
    [value]="tableData"
    [(selection)]="selectedRow"
    (selectionChange)="emitSelectedRow()"
    selectionMode="single"
    [scrollable]="true"
    scrollHeight="430px"
  >
    <ng-template pTemplate="caption">
      <div class="material-table__caption">
        <div class="material-table__caption--size" [title]="tableData.length"></div>
        <i class="material-table__caption--search-icon icon-barcode"></i>
        <input
          type="text"
          pInputText
          size="50"
          placeholder="{{ 'MOUNTING.SEARCH_BAR_PLACEHOLDER' | translate }}"
          (input)="dt.filterGlobal($event.target.value, 'contains')"
        />
      </div>
    </ng-template>

    <ng-template pTemplate="header" let-columns>
      <tr>
        <th
          [ngClass]="col.style"
          class="material-table__header"
          *ngFor="let col of columns"
          [pSortableColumn]="col.field"
          [style.width]="col.width + 'px'"
          [style.height]="'50px'"
        >
          {{ col.header | translate }}
          <p-sortIcon [field]="col.field"></p-sortIcon>
        </th>
      </tr>
    </ng-template>

    <ng-template pTemplate="emptymessage" let-columns>
      <tr>
        <td [attr.colspan]="columns.length">{{ 'MOUNTING.MACHINE_NO_PALLETS_AVAILABLE' | translate }}</td>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-rowData let-columns="columns">
      <tr [pSelectableRow]="rowData">
        <ng-container *ngFor="let col of columns">
          <td [ngClass]="col.style" [style.width]="col.width + 'px'">
            <ng-container [ngSwitch]="col.type">
              <div *ngSwitchCase="'status'">
                <ng-container *ngIf="rowData[col.field]">
                  <lib-quality-status [checkClass]="'status__label--nok-active'" [checkText]="'QA'"></lib-quality-status>
                </ng-container>
              </div>

              <div *ngSwitchCase="'datetime'">
                {{ rowData[col.field] | utcToLocal | date : 'dd.MM.yyyy, HH:mm' }}
              </div>
              <div *ngSwitchCase="'number'">{{ rowData[col.field] | number }}</div>
              <div *ngSwitchCase="'quantity'">{{ rowData[col.field] | formatQuantity }}</div>
              <!-- <div *ngSwitchCase="'time'">{{ rowData[col.field] | utcToLocal | date: 'HH:mm' }}</div> -->
              <div *ngSwitchCase="'sscc'"><span [innerHTML]="rowData[col.field] | sscc"></span></div>
              <div *ngSwitchDefault>{{ rowData[col.field] }}</div>
            </ng-container>
          </td>
        </ng-container>
      </tr>
    </ng-template>
  </p-table>
</section>
