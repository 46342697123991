/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

export enum RunSubPhaseType {
  None = 'None',
  SetupLineClearance = 'SetupLineClearance',
  SetupQaHistory = 'SetupQaHistory',
  SetupParameter = 'SetupParameter',
  SetupPatternInfo = 'SetupPatternInfo',
  SetupMaterialWaste = 'SetupMaterialWaste',
  SetupInProgressFinishingCheck = 'SetupInProgressFinishingCheck',
  Run = 'Run',
  FinishingOverview = 'FinishingOverview',
  FinishingToolCheckout = 'FinishingToolCheckout',
  FinishingEnd = 'FinishingEnd'
}
