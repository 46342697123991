<lib-header-layout>
  <lib-title [applicationName]="'APP.PILETURNER_APPLICATION' | translate" [workCenterName]="currentPileTurnerName$ | async"></lib-title>
  <lib-info-log></lib-info-log>
  <lib-version-number [version]="version"></lib-version-number>
  <lib-multi-language>
    <lib-dropdown
      [options]="supportedLanguages$ | async"
      [ngModel]="currentLanguage$ | async"
      (ngModelChange)="onLanguageSelected($event)"
      [virtualScroll]="true"
      [inlineStyle]="{ 'min-width': '125px' }"
    ></lib-dropdown>
  </lib-multi-language>
  <lib-user>
    <ng-container *ngIf="hasWorkCenterSelection$ | async">
      <lib-dropdown
        [options]="pileTurnerOptions$ | async"
        [(ngModel)]="currentPileTurnerId"
        (ngModelChange)="onPileTurnerChange($event)"
        [inlineStyle]="{ 'min-width': '175px' }"
        [virtualScroll]="true"
        [panelWidth]="{ 'max-height': '148px' }"
      ></lib-dropdown>
    </ng-container>
  </lib-user>
  <lib-environment *ngIf="(isProductionEnvironment$ | async) === false" [message]="'HEADER.NON_PRODUCTION' | translate"></lib-environment>
</lib-header-layout>
