import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { MenuItem } from 'chronos-shared';
import { of } from 'rxjs';

@Component({
  selector: 'pt-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.scss']
})
export class MainMenuComponent {
  public readonly menuItems: MenuItem[] = [
    {
      link: '/',
      tooltip: 'MAIN_MENU.PILE_TURNER',
      icon: 'icon-mounting',
      onClick: () => this.router.navigate(['/']),
      isActiveMatchOptions: { paths: 'subset', queryParams: 'subset', fragment: 'ignored', matrixParams: 'ignored' }
    },
    {
      tooltip: 'MAIN_MENU.INFO',
      icon: 'icon-info-solid',
      isActiveMatchOptions: { paths: 'subset', queryParams: 'subset', fragment: 'ignored', matrixParams: 'ignored' }
    }
  ];
  public readonly menuItems$ = of(this.menuItems);

  constructor(private router: Router) {}
}
